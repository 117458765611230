<template>
    <div class="header">
        <div class="h-wrap">
            <a class="logo" @click="movePagePush('/info')"><img src="@/assets/image/rebuild/ami_logo.png" alt=""></a>
            <ul class="menu">
                <li class="mentor_list" ><span  @click="movePage('/info?mt=1')">멘토 소개</span></li>
                <li class="letter" :class="{'active' : $route.meta.name === 'Home' || $route.meta.name === 'Letter'}" @click="movePage('/')"><span>꿈편지</span></li>
                <li class="commu" :class="{'active' : $route.meta.name === 'Community'}" @click="movePage('/commu')"><span>멘토&멘티 소통</span></li>
                <li class="amidan" :class="{'active' :$route.meta.name === 'Amidan'}"><span @click="movePagePush('/amidan')">아미단활동</span></li>
                <li class="notice" :class="{'active' :$route.meta.name === 'Notice'}"><span @click="movePagePush('/notice')">공지사항</span></li>
                <li :class="{'active' : ['Faq', 'Terms'].indexOf($route.meta.name) > -1}">
                    <span>서비스정보</span>
                    <ul class="s-menu">
                        <li  @click="movePagePush('/faq')"><span>FAQ</span></li>
                        <li  @click="movePagePush('/terms')"><span>약관</span></li>
                    </ul>
                </li>
            </ul>
            <div class="info">
                <img src="@/assets/image/rebuild/default.png" v-if="returnProfileImage() === ''">
                <img :src="returnProfileImage()" alt="" v-else width="47" height="47">
                <div class="profile">
                    <div class="p-name"> {{UserInfo.mb_name}} </div>
                    <div class="logout cursor" @click="UserLogout()"> 로그아웃 </div>
                </div>
                <div class="alarm" @click="movePage('/alarm')">
                    <span class="cursor" :class="{'on': alarmList.length > 0}"> <!--알림이 있는경우 on 클래스 추가-->
                        <img src="@/assets/image/rebuild/top_new.png">알림
                    </span>
                </div>
                <div class="mypage" @click="movePage(`/mypage/${UserInfo.mb_no}`)">
                    <span class="cursor"><img src="@/assets/image/rebuild/top_my.png">마이페이지</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters, mapState} from "vuex";
    import {logout} from "@/mixins/Auth";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: 'MenteeHeader',
        components: {},
        mixins: [imageOption],
        props: {
            msg: String,
        },
        data() {
            return {
            }
        },
        created(){

        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
                alarmList: state => state.alarm.list,
            }),
        },
        methods:{
            UserLogout() {
                logout();
            },
            returnProfileImage() {
                return this.UserInfo.hasOwnProperty('Profile') && this.UserInfo.Profile.length > 0 ? `${this.UserInfo.Profile[0].org_url}?${this.setImageOptions(47, 47, 'png')}` : ''
            },
            movePage(path) {
                if(this.$route.path === path) {
                    return ;
                }
                // this.$router.push(path);
                location.href = path;
            },

            movePagePush(path) {
                if(this.$route.path === path) {
                    return ;
                }
                this.$router.push(path);
            },
        },
        watch: {

        }
    }

</script>
